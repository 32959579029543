
const Title = "Cognito ADFS Test"

// const CognitoConfig = {
//     userPoolClientId: '5u1fdq97psftkjjd1guctainaj', 
//     region: 'eu-west-1', // e.g. us-east-2
//     authDomainPrefix: 'hcopstest', // e.g. leosaml
//     login_redirect_uri:'https://development-federation-auth-test.hcapp.nl',   
//     logout_redirect_uri: 'https://development-federation-auth-test.hcapp.nl',
//     authProvider:'hcopstest',
//     scope1: {
//         phone: false,
//         email: false,
//         openid: true,
//         admin: true,
//         profile: true
//     },
//     scope:'aws.cognito.signin.user.admin%20openid%20profile',
//     response_type:'token'
// };

const CognitoConfig = {
    userPoolClientId: '77ag8egq9favuvvrbmhrjjv93r', 
    region: 'eu-west-1', // e.g. us-east-2
    authDomainPrefix: 'hcapp', // e.g. leosaml
    login_redirect_uri:'https://development-federation-auth-test.hcapp.nl',   
    logout_redirect_uri: 'https://development-federation-auth-test.hcapp.nl',
    authProvider:'HollandCasino',
    scope1: {
        phone: false,
        email: false,
        openid: true,
        admin: true,
        profile: true
    },
    scope:'aws.cognito.signin.user.admin%20openid%20profile',
    response_type:'TOKEN'
};

const LoginUri = "https://" +
               CognitoConfig.authDomainPrefix +
               ".auth." +
               CognitoConfig.region +
               ".amazoncognito.com" +
               "/login?response_type=token" +
               "&client_id=" + CognitoConfig.userPoolClientId +
               "&redirect_uri=" + CognitoConfig.login_redirect_uri;
                   
const AuthUri = "https://" + CognitoConfig.authDomainPrefix + ".auth." +  CognitoConfig.region + ".amazoncognito.com" +
               "/oauth2/authorize" + 
               "?identity_provider=" + CognitoConfig.authProvider +
               "&redirect_uri=" + CognitoConfig.login_redirect_uri +
               "&client_id=" + CognitoConfig.userPoolClientId +
               "&scope=" + CognitoConfig.scope + 
               "&response_type=" + CognitoConfig.response_type;

// const CognitoConfig = {
//     userPoolClientId: '648fphm8g5pvklsqi2lq6ln2hh', 
//     region: 'eu-west-1', // e.g. us-east-2
//     authDomainPrefix: 'hcopstest', // e.g. leosaml
//     login_redirect_uri:'https://do2cfoim0ued8.cloudfront.net',   
//     logout_redirect_uri: 'https://do2cfoim0ued8.cloudfront.net',
//     authProvider:'hcopstest',
//     scope:'aws.cognito.signin.user.admin%20openid%20profile',
//     response_type:'TOKEN'
// };

exports.Title           = Title;
exports.CognitoConfig   = CognitoConfig;
exports.LoginUri        = LoginUri;
exports.AuthUri         = AuthUri;


