
const createLoginUri = (config) => {

  return `https://${config.authDomainPrefix}.auth.${config.region}.amazoncognito.com/login` +
         `?response_type=${config.response_type}&client_id=${config.userPoolClientId}&redirect_uri=${config.login_redirect_uri}`;
  
  // return `https://${config.authDomainPrefix}.auth.${config.region}.amazoncognito.com/login` +
  //       `?response_type=${config.response_type}&client_id=${config.userPoolClientId}&redirect_uri=${config.login_redirect_uri}`;
};

const createAuthUri = (config) => {
  console.log(`Config for LoginUri is: ${config}`);
  let scope = [ config.scope1.phone ? 'phone' : '', 
                config.scope1.email  ? 'email' : '', 
                config.scope1.openid  ? 'openid' : '', 
                config.scope1.admin  ? 'aws.cognito.signin.user.admin' : '',
                config.scope1.profile  ? 'profile' : ''];
                
  scope = scope.join('%20');
  let authUrl = `https://${config.authDomainPrefix}.auth.${config.region}.amazoncognito.com/oauth2/authorize` +
    `?identity_provider=${config.authProvider}&redirect_uri=${config.login_redirect_uri}&client_id=${config.userPoolClientId}` +
    `&scope${scope}&response_type=${config.response_type}`;
  
  console.log(`Config for AuthUri is: ${config}`);
  console.log(`AuthUri is: ${authUrl}`);
  return  authUrl;


          // https://hcapp.auth.eu-west-1.amazoncognito.com/oauth2/authorize
          // ?identity_provider=HollandCasino&redirect_uri=https://development-federation-auth-test.hcapp.nl&client_id=77ag8egq9favuvvrbmhrjjv93r
          // &scope=aws.cognito.signin.user.admin%20openid%20profile&response_type=TOKEN
        };

const createLogoutUri = (config) => {
  return `https://${config.authDomainPrefix}.auth.${config.region}.amazoncognito.com/logout` +
         `?client_id=${config.userPoolClientId}&logout_uri=${config.logout_redirect_uri}`;
};

exports.createLoginUri  = createLoginUri;
exports.createAuthUri   = createAuthUri;
exports.createLogoutUri = createLogoutUri;