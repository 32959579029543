import * as React from "react";
import { useState, useEffect } from 'react';
import {  useLocation } from 'react-router-dom';
import './App.css';

import NavBar         from './components/NavBar';
import CognitoConfig  from './components/CognitoConfig';
import Config         from './config/Config';
import DisplayTokens  from './components/DisplayTokens';
//import TestJwt        from './config/JwtSample';
import { createAuthUri, createLoginUri, createLogoutUri } from './utils/createUris';
import { create } from "@mui/material/styles/createTransitions";

function App() {
  const [config, setConfig] = useState(Config.CognitoConfig);
  const [tokens, setTokens] = useState('');
  let location = useLocation();
  
  useEffect(() => {
    if(typeof location.hash !== undefined) {
      setTokens(location.hash);
      console.log(`Location: ${location.hash}`);
    }
  }, [location]);
  
  
  useEffect(() => {
    document.title = 'Cognito Test';
  },[]);
  
  const onLogin = () => {
    let url = createAuthUri(config);
     //console.log(`Redirecting to: ${createLoginUri(config)}`);
    console.log(`Redirecting to : ${url}`);
    window.location = url;
  };
  
  const onLogout = () => {
     console.log(`Redirecting to: ${createLogoutUri(config)}`);

    window.location = createLogoutUri(config);
  };
  
  const onConfigChange = (config) => {
    console.log(JSON.stringify(config));
    setConfig(config);
  };
  
  return (
    
      <div className="App">
        <NavBar title={ Config.Title } onLogin={ onLogin } onLogout={ onLogout }/>
        <CognitoConfig config={config} onConfigChange={onConfigChange }/>
        <DisplayTokens tokens={ tokens }/>
      </div>
    
  );
}

export default App;
